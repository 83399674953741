@import '../../styles/customMediaQueries.css';
.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 6px;
  padding-bottom: 6px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
  @media (--viewportMedium) {
    padding-top: 26px;
    padding-bottom: 26px;
  }
  &.disabled {
    opacity: 0;
  }
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
}

.arrowIcon {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor);

  &:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 160px;
}

.pageNumberList5Items {
  flex-basis: 200px;
}

.pageNumberList6Items {
  flex-basis: 250px;
}

.pageNumberList7Items {
  flex-basis: 275px;
}

.toPageLink {
  /* Dimensions */

  /* Color for svg icons */
  color: var(--colorTextDark);
  background-color: var(--colorText);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: none;
  }
}

.currentPage {
  opacity: 1;
  color: var(--colorText);
  background-color: var(--marketplaceColorDark);
}

.paginationGap {
  /* Dimensions */

  /* Color for svg icons */
  color: var(--colorTextDark);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}
