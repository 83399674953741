.bottomNavbar {
  display: none;
  justify-content: space-around;
  position: fixed;
  border-radius: 20px;
  margin: 0 auto;
  width: calc(100% - 48px);
  left: 0;
  right: 0;
  bottom: 12px;
  background-color: var(--colorText);
  z-index: 10;
  border-top: solid 1px var(--colorGrey100);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    padding: 11px 2px 17px 2px;
  }

  & > a {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px 10px 10px;
    opacity: 0.6;

    @media (max-width: 480px) {
      padding: 8px 8px 0px;
      font-size: 13px;
    }

    @media (max-width: 375px) {
      font-size: 11px;
    }
    &.favoriteIcon {
      & > svg {
        width: 24px;
        & > path {
          stroke-width: 40px;
        }
      }
    }
    &.active,
    &:hover {
      opacity: 1;
    }

    &.blogIcon {
      & > svg,
      & > img {
        opacity: 0.7;
      }
    }
    & > svg,
    & > img {
      fill: none;
      width: 40px;
      margin-bottom: 1px;
      margin-top: 1px;

      @media (max-width: 480px) {
        width: 30px;
      }
    }
  }
  & > span {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 10px 10px 10px;
    opacity: 0.6;

    @media (max-width: 480px) {
      padding: 8px 8px 0px;
      font-size: 13px;
    }

    @media (max-width: 375px) {
      font-size: 11px;
    }

    &.active,
    &:hover {
      opacity: 1;
    }

    &.blogIcon {
      & > svg,
      & > img {
        opacity: 0.7;
      }
    }
    & > svg,
    & > img {
      fill: none;
      width: 40px;
      margin-bottom: 1px;
      margin-top: 1px;

      @media (max-width: 480px) {
        width: 30px;
      }
    }
  }
}
