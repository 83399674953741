@import '../../../styles/customMediaQueries.css';

.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: flex !important;
  flex-direction: column;
  position: relative;
  background-color: var(--marketplaceColorLightBg);
  @media (--viewportMedium) {
    padding-bottom: 294px;
  }
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

/* Container for side and content */
.container {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.main {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 48px 36px 82px 59px;
    background-color: var(--marketplaceColorLightBg);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 48px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.sideNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  overflow-x: scroll;

  @media (--viewportMedium) {
    padding: 72px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 72px 0 82px calc((100% - 1056px) / 2);
  }
}

.tabs {
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 48px;
  }

  @media (--viewportMedium) {
    min-height: auto;
    flex-direction: column;
    padding-top: 0;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
