@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 76px;

  @media (--viewportMedium) {
    padding-bottom: 90px;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.location {
  display: flex;
  align-items: flex-start;
  color: var(--colorTextDark);
  font-size: 15px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & > svg {
    fill: none;
    width: 20px;
    min-width: 20px;
    height: 16px;
    margin-right: 10px;
    & path {
      stroke: var(--colorTextDark) !important;
      stroke-width: 2 !important;
      /* fill: var(--colorTextDark); */
    }
  }
  & > span {
    line-height: 120%;
  }

  &.locationPin {
    & > svg {
      width: 20px;
      height: 20px;

      & path {
        fill: none;
        stroke: var(--colorTextDark);
      }
    }
  }
}

.loadingText {
  margin: 64px;
  display: flex;
  justify-content: center;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.sectionHero {
  position: relative;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    overflow-y: hidden;
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  color: hsl(106.57deg 26.69% 50.78%);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: center;
  }
}

.actionBarForProductLayout {
  margin: 0 0 24px 0;
  width: 100%;
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 0px 0 10px 0;
  padding: 0;
  color: var(--colorTextDark);
  font-size: 20px;

  @media (--viewportSmall) {
    margin: 0px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorFail);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h5 from global;
  composes: buttonDefault from global;
  width: auto;
  height: auto;
  min-height: auto;
  flex-shrink: 0;
  margin: 0;
  padding: 10px 24px;
  color: var(--colorText);

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: calc(var(--contentMaxWidth) + 72px);
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: var(--contentMaxWidth);
    padding: 0;
  }
}

.contentWrapperForProductLayout {
  background-color: var(--marketplaceColorLightBg);
  width: 100%;
  padding: 30px 0px;
  box-sizing: border-box;
  height: 100%;

  @media (--viewportLarge) {
    padding: 50px 0px;
    height: auto;
  }
}

.mainColumnForProductLayout {
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }

  & .productGallery {
    position: relative;
    background-color: var(--colorText);
    border-radius: 20px;

    @media (--viewportMedium) {
      padding: 24px;
    }

    & :global(.slick-arrow) {
      background-color: var(--colorText);
      border-radius: 100px;
      width: 40px;
      height: 40px;

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    & :global(.slick-arrow.slick-prev) {
      left: 20px;
    }

    & :global(.slick-arrow.slick-next) {
      right: 20px;
    }
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBlack);
  color: var(--colorText);
  font-size: 25px;
  line-height: 100%;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 35px;
  }
}

.orderPanelSubTitle {
  font-weight: var(--fontWeightBlack);
  color: var(--colorTextDark);
  font-size: 25px;
  line-height: 100%;
  width: 100%;
  margin: 20px 0;
  text-transform: uppercase;
  font-family: var(--fontFamily1);
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.sectionMap {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.sectionReviews,
.sectionAuthor {
  margin-top: 42px;
}

.details {
  margin: 0 0 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & .detailsRow {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% / 2 - 24px);
    list-style: none;
    font-weight: var(--fontWeightRegular);
    margin: 15px 0;

    & .detailLabel {
      font-weight: var(--fontWeightRegular);
      color: var(--colorTextDark);
      font-size: 15px;
      line-height: 100%;
      margin: 0 0 10px 0;
    }

    & .detailValue {
      font-weight: var(--fontWeightBold);
      color: var(--colorTextDark);
      font-size: 20px;
      line-height: 100%;
    }
  }
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 100%;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  border-radius: var(--borderRadiusLarge);
  overflow: hidden;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    margin-bottom: 7px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-word;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0 0 20px 0;

  @media (--viewportLarge) {
    padding-bottom: 4px;
  }
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.mainContentWrapper {
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }

  & .mainContentLeft {
    width: 100%;

    @media (--viewportLarge) {
      width: calc(100% - 400px);
      padding-right: 40px;
    }

    & .listingPageHeader {
      border-bottom: solid 1px var(--colorGrey100);
      align-items: flex-start;
      padding-bottom: 32px;
      margin-bottom: 32px;

      & .listingPageHeaderLeft {
        & .orderPanelTitle {
          margin-bottom: 8px;
        }
      }
    }

    & .userProfile {
      width: 56px;
      height: 56px;
      border-radius: 100px;
      overflow: hidden;
      position: relative;

      & > img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    & .experienceSec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 32px;
      align-items: flex-start;

      @media (--viewportSmall) {
        grid-template-columns: repeat(3, 1fr);
      }

      & .experienceBlock {
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-bottom: 0;
        }

        & .experienceIcon {
          & > svg {
            width: 20px;
            height: 20px;

            & path {
              fill: var(--colorBlack);
              opacity: 1;
            }
          }
        }

        & .experienceInfo {
          margin-top: 15px;

          & > h2 {
            font-size: 18px;
            color: var(--colorBlack);
            margin: 0 0 8px 0;
            line-height: 100%;
          }

          & > p {
            font-size: 16px;
            color: var(--colorGrey500);
            margin: 0;
            line-height: 100%;
          }
        }
      }
    }
  }

  & .mainContentRight {
    width: 100%;

    @media (--viewportLarge) {
      max-width: 400px;
    }

    & .orderColumnForProductLayout {
      @media (--viewportLarge) {
        border: solid 1px rgba(17, 17, 17, 0.04);
        padding: 20px;
        border-radius: 20px;
        background-color: var(--colorText);
      }
    }

    & .productOrderPanel {
      @media (--viewportLarge) {
        display: block;
      }
    }
  }
}

.mainContentRightHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    gap: 24px;
  }

  &.spaceBetweenClass {
    justify-content: space-between;
  }

  & .privateIcon {
    display: inline-flex;
    align-items: center;
    flex-direction: column;

    & > svg {
      margin-bottom: 2px;
    }

    & > span {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorBlack);
    }
  }

  & .instantBookingIcon {
    padding: 4px 10px;
    line-height: 110%;
    font-size: 14px;
    border: 1px solid hsl(80deg 84% 95.1%);
    border-radius: 4px;
    background: #f6fde8;

    @media (max-width: 375px) {
      font-size: 12px;
    }

    & > svg {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
      margin-right: 4px;
      fill: #f6fde8;
    }

    & > span {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: var(--fontWeightRegular);
      color: var(--colorBlack);
    }
  }

  & .parkingIcon {
    background-color: var(--colorText);
    padding: 6px 10px;
    border-radius: var(--borderRadius);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > svg {
      margin-right: 8px;
    }
  }

  & .flag {
    & > img {
      width: 52px;
      border-radius: 4px;
    }
  }
}

.amenitiesSec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 0 32px;

  &.vehicleAmenitiesSec {
    flex-wrap: wrap;
    gap: 0;

    & .amenitieBlock {
      width: calc(100% / 2);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 24px;

      @media (--viewportSmall) {
        width: calc(100% / 2);
      }

      & > img {
        max-width: 100%;
        width: 40px;
        margin-right: 10px;

        @media (--viewportSmall) {
          width: 60px;
          margin-right: 20px;
        }

        @media (--viewportMedium) {
          margin-right: 32px;
        }
      }

      & > span {
        text-align: left;
      }
    }
  }

  & .amenitieBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 24px;
    width: calc(100% / 3 - 24px);

    &.disabled {
      opacity: 0.4;

      & > span {
        text-decoration: line-through;
        word-wrap: break-word;
        word-break: break-word;
      }
    }

    & > img {
      max-width: 100%;
      width: 50px;

      @media (--viewportSmall) {
        width: 60px;
      }
    }

    & > span {
      font-size: 15px;
      line-height: 100%;
      font-weight: var(--fontWeightRegular);
      color: var(--colorTextDark);
      word-wrap: break-word;
      word-break: break-word;

      @media (--viewportSmall) {
        font-size: 18px;
      }
    }
  }
}

.description {
  margin-bottom: 24px;

  & > p {
    font-size: 15px;
    color: var(--colorTextDark);
    margin: 0;
    word-wrap: break-word;

    &.showMore {
      margin: 0;
    }
  }
}

.note {
  margin: 24px 0;

  & > h2 {
    color: var(--colorTextDark);
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 100%;
  }

  & > p {
    color: var(--colorText);
    font-size: 15px;
    line-height: 130%;
    margin: 0;
  }
}

.pitchesInfo {
  margin-top: 40px;
}

.headingSection {
  max-width: var(--contentMaxWidthPages);
  width: 100%;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

.campingInContent {
  & > h2 {
    color: var(--colorText);
    font-size: 50px;
    font-weight: var(--fontWeightBlack);
    line-height: 120%;
    padding: 0;
    margin: 0 0 20px 0;
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto 20px;
    text-align: center;

    @media (--viewportMedium) {
      margin: 0 auto 47px;
    }
  }

  & :global(.slick-arrow) {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: var(--colorText);
  }

  & :global(.slick-prev) {
    left: 30px;
  }

  & :global(.slick-next) {
    right: 30px;
  }

  & .sliderItem {
    padding-bottom: 100%;
    min-height: auto;
    position: relative;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    transition: var(--transitionStyle);

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      transition: var(--transitionStyle);
    }

    & > h2 {
      color: var(--colorText);
      font-size: 30px;
      line-height: 130%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-shadow: 0px 0px 8px #000000a3;
    }
  }
}

.sliderItem {
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: var(--boxShadowNotFoundPageSearch);
  min-height: 400px;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  & .sliderItemContent {
    position: absolute;
    background-color: #6ea36099;
    width: 100%;
    border-radius: 20px;
    padding: 14px 20px;
    bottom: 0;
    left: 0;
    text-align: center;

    & > h2 {
      color: var(--colorText);
      font-size: 25px;
      font-weight: var(--fontWeightBlack);
      line-height: 120%;
      padding: 0;
      margin: 0 0 4px 0;
    }

    & > p {
      color: var(--colorText);
      font-size: 10px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 13px 0;
      padding: 0;
    }

    & > button {
      background-color: transparent;
      border: solid 2px var(--colorText);
      color: var(--colorText);
      font-size: 14.5px;
      line-height: 120%;
      padding: 8px 20px;
      display: inline-block;
      text-align: center;
      width: auto;
      min-height: auto;
      height: auto;
      border-radius: 100px;
    }
  }
}

.imageGallary {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  flex-direction: column;

  &.imageGallary2 {
    & .gallarySec {
      order: 2;
    }

    & .sectionFilter {
      order: 1;
      padding-left: 0px;
      padding-right: 30px;

      @media (--viewportLarge) {
        padding-right: 60px;
      }
    }
  }

  & .gallarySec {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 14px;
    width: 100%;
    order: 2;

    @media (max-width: 767px) {
      padding: 30px;
    }

    @media (--viewportMedium) {
      grid-gap: 20px;
    }

    @media (--viewportLarge) {
      grid-gap: 24px;
    }

    & .imgBlock {
      border-radius: 10px;
      padding-bottom: 20%;
      position: relative;
      overflow: hidden;
      display: grid;
      transition: var(--transitionStyle);
      min-height: 150px;

      @media (--viewportMedium) {
        border-radius: 20px;
        padding-bottom: 30%;
      }

      &:hover {
        transition: var(--transitionStyle);

        & > img {
          transform: scale(1.05);
        }
      }

      & > img {
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        transition: var(--transitionStyle);
      }

      & > h2 {
        color: var(--colorText);
        font-size: 30px;
        line-height: 130%;
        font-weight: var(--fontWeightBlack);
        margin: 0 0 10px 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        text-shadow: 0px 0px 8px #000000a3;

        @media (--viewportSmall) {
          font-size: 35px;
        }
      }

      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 3;
        grid-row-end: 5;
      }

      &:nth-child(3) {
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 5;
      }

      &:nth-child(4) {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 5;
        grid-row-end: 7;
      }
    }
  }

  & .sectionFilter {
    order: 1;
    width: 100%;
    margin-bottom: 30px;

    & > img {
      max-width: 100%;
      width: 150px;
    }

    & > h2 {
      color: var(--colorText);
      font-size: 35px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 10px 0;
      padding: 0;

      @media (--viewportSmall) {
        font-size: 50px;
      }
    }

    & > p {
      color: var(--colorText);
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;

      @media (--viewportSmall) {
        font-size: 20px;
      }
    }

    & > button {
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
      padding: 15px 30px;
      border-radius: 100px;
      display: inline-block;
      text-align: center;
      font-size: 15px;
      line-height: 120%;
      width: auto;
      min-width: 200px;
      min-height: auto;

      @media (--viewportSmall) {
        font-size: 15px;
      }
    }
  }
}

.seoSections {
  margin-top: 42px;
  overflow: hidden;

  & :global(.slick-slider) {
    margin: 0 -15px;
  }

  & :global(.slick-track) {
    margin: 0;
  }

  & :global(.slick-arrow) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;

    &::before {
      display: none;
    }
  }

  & :global(.slick-slide) {
    padding: 10px 15px;

    & :global(.slick-slider) {
      margin: 0;

      & :global(.slick-slide) {
        padding: 0;
      }
    }
  }
}

.seoSlider {
  & .fullSlider {
    & :global(.slick-slider) {
      margin: 0 -15px;
    }

    & :global(.slick-track) {
      margin: 0;
    }

    & :global(.slick-arrow) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      width: 40px;
      height: 40px;

      &::before {
        display: none;
      }
    }

    & :global(.slick-prev) {
      left: 30px;
    }

    & :global(.slick-next) {
      right: 30px;
    }

    & :global(.slick-slide) {
      padding: 10px 15px;

      & :global(.slick-slider) {
        margin: 0;

        & :global(.slick-slide) {
          padding: 0;
        }
      }
    }
  }
}

.nearBuyListings {
  margin-top: 42px;

  & :global(.slick-slider) {
    margin: 0 -15px;
  }

  & :global(.slick-track) {
    margin: 0;
  }

  & :global(.slick-arrow) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 40px;

    &::before {
      display: none;
    }
  }

  & :global(.slick-prev) {
    left: 30px;
  }

  & :global(.slick-next) {
    right: 30px;
  }

  & :global(.slick-slide) {
    padding: 10px 15px;

    & :global(.slick-slider) {
      margin: 0;

      & :global(.slick-slide) {
        padding: 0;
      }
    }
  }
}

.personSec {
  & .personSecHead {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .personSecHeadLeft {
      & > p {
        margin: 0;
        font-size: 15px;
        line-height: 120%;
        color: var(--colorTextDark);

        & > strong {
          font-weight: var(--fontWeightBold);
          font-size: 20px;
        }
      }
    }
  }

  & .selectedPersonsSec {
    & .personsBlock {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      & > * {
        width: calc(100% / 3);
      }

      & .firstblock {
        & > h3 {
          font-size: 20px;
          line-height: 120%;
          color: var(--colorTextDark);
          font-weight: var(--fontWeightBold);
          margin: 0;
        }

        & > p {
          margin: 0;
          font-size: 15px;
          line-height: 120%;
          color: var(--colorTextDark);
        }
      }

      & .middleblock {
        text-align: center;

        & > h3 {
          font-size: 20px;
          line-height: 120%;
          color: var(--colorTextDark);
          font-weight: var(--fontWeightBold);
          margin: 0;
        }
      }

      & .lastblock {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        & > h3 {
          font-size: 20px;
          line-height: 120%;
          color: var(--colorTextDark);
          font-weight: var(--fontWeightBold);
          margin: 0 10px;
          min-width: 30px;
          text-align: center;
        }

        & > button {
          cursor: pointer;
          background-color: var(--colorTextDark);
          color: var(--colorText);
          font-size: 28px;
          border-radius: 100px;
          width: 34px;
          height: 34px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          line-height: 0;
          font-weight: var(--fontWeightRegular);
          outline: none;
          border: none;
        }
      }
    }
  }
}

.claimListingPanel {
  padding-bottom: 100px;

  @media (--viewportLarge) {
    padding-bottom: 0px;
  }
}

.claimListingPanelHead {
  margin-bottom: 15px;

  & .orderPanelTitle {
    color: var(--marketplaceColorDark);
    margin-bottom: 10px;
    line-height: 100%;
  }

  & > p {
    color: var(--colorTextDark);
    margin: 0;
    font-size: 16px;
    line-height: 140%;
    text-align: justify;
  }
}

.claimListingPanelBody {
  border-radius: 20px;
  background-color: var(--colorText);

  @media (--viewportLarge) {
    border: solid 1px rgba(17, 17, 17, 0.04);
    padding: 20px;
  }

  @media (--viewportLarge) {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    border: none;
  }

  & .formRow {
    display: flex;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    & .formFld {
      width: 100%;
      margin-bottom: 24px;

      @media (--viewportSmall) {
        width: 100%;
      }

      & input {
        background-color: var(--marketplaceColorLightBg);
        color: var(--colorText);
        border: none;
        box-shadow: none;
        border-radius: 100px;

        &::placeholder {
          color: var(--colorText);
          opacity: 1;
        }
      }

      & .textarea {
        & textarea {
          min-height: 150px;
          border-radius: 20px;
          background-color: var(--marketplaceColorLightBg);
          color: var(--colorText);
          border: none;
          box-shadow: none;

          &::placeholder {
            color: var(--colorText);
            opacity: 1;
          }
        }
      }

      & > label {
        font-size: 15px;
        color: var(--colorGrey600);
        padding: 0;
        margin-bottom: 8px;
        margin-top: 0;
      }

      & :global(.DateRangePicker) {
        width: 100%;
      }

      & :global(.DateRangePickerInput) {
        width: 100%;
        background-color: transparent;
        padding: 0;
      }

      & :global(.DateInput) {
        width: calc(100% / 2 - 12px);
        background: transparent;
        margin-bottom: 24px;

        &:not(:last-of-type) {
          margin-right: 24px;
        }
      }

      & :global(.DateInput_input) {
        font-weight: var(--fontWeightRegular);
        font-size: 16px;
      }

      & :global(.DateRangePickerInput__withBorder) {
        border: none;
      }

      & :global(.DateRangePickerInput_arrow) {
        display: none;
      }
    }

    &.halfWidth {
      & .formFld {
        width: 100%;
        margin-bottom: 24px;

        @media (--viewportSmall) {
          width: calc(100% / 2 - 12px);
        }

        &:not(:last-child) {
          @media (--viewportSmall) {
            margin-right: 24px;
          }
        }
      }
    }

    &.dateFld {
      & .formFld {
        margin-bottom: 0;
      }
    }

    &.checkboxFormRow {
      & .formFld {
        margin-bottom: 10px;
      }
    }

    & .submitButton {
      margin: 10px 0;
      width: auto;
      min-height: auto;
      padding: 8px 24px;
    }
  }
}

.customSelect {
  & input {
    min-height: 46px;
  }

  & svg {
    fill: var(--colorText);
  }

  & .option {
    display: flex;
    align-items: center;

    & > img {
      max-width: 100%;
      width: 40px;
    }

    & > span {
      font-size: 14px;
      color: var(--colorText);
      margin-left: 14px;
      font-weight: var(--fontWeightMedium);
    }
  }
}

.claimListingSec {
  & > p {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 18px;
    line-height: 100%;
    font-weight: var(--fontWeightSemiBold);
    color: var(--colorTextDark);
    word-wrap: break-word;
    word-break: break-word;
    font-size: 20px;

    @media (--viewportSmall) {
      font-size: 20px;
    }
  }

  & .claimBtn {
    width: auto;
    padding: 10px 24px;
    min-height: auto;
    cursor: pointer;
  }
}

.claimBtn {
  cursor: pointer;
  background-color: var(--colorText);
  border: solid 2px var(--marketplaceColor);
  padding: 7px 20px;
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: var(--marketplaceColor);
  font-size: 14px;
  line-height: 120%;
  font-weight: var(--fontWeightBold);
  letter-spacing: 0.3px;
  margin-top: 15px;
  transition: var(--transitionStyle);
  margin-left: 10px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }

  &:hover {
    transition: var(--transitionStyle);
    text-decoration: none;
    background-color: var(--marketplaceColor);
    background-color: var(--marketplaceColor);
    color: var(--colorText);
  }
}

.noVehicleInformation {
  font-weight: var(--fontWeightBold);
  color: var(--colorTextDark);
  font-size: 20px;
  line-height: 100%;
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 17px 24px;
  background-color: var(--colorText);
  box-shadow: var(--boxShadowTop);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }

  & > button {
    min-height: auto;
    padding: 8px 20px;
    min-width: 200px;
    border-radius: 100px;
    width: auto;
  }

  & .instantBookingIconMobile {
    background-color: var(--colorText);
    color: var(--marketplaceColor);
    padding: 0px;
    line-height: 120%;
  }
}

.infoOnlyButton {
  & > button {
    min-width: 380px !important;
  }
}

.dateFld {
  position: relative;

  @media (--viewportLarge) {
    & :global(.DateRangePicker) {
      width: 100%;
      height: 100%;
    }

    & :global(.DateRangePicker > div) {
      height: 100%;
    }

    & :global(.DayPickerNavigation_button__verticalDefault) {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      & > svg {
        width: 20px;
        height: 20px;
      }
    }

    & :global(.DateRangePickerInput) {
      display: flex;
      height: 100%;
      background-color: transparent;
      border: none;
    }

    & :global(.DateRangePicker_picker) {
      position: relative;
      top: 80% !important;
      order: 1;
      height: 100%;
      background-color: transparent;
    }

    & :global(.CalendarMonthGrid) {
      background-color: transparent;
    }

    & :global(.DayPicker__horizontal) {
      background-color: transparent;
    }

    & :global(.DayPicker__withBorder) {
      box-shadow: none;
      background-color: transparent;
    }

    & :global(.CalendarMonth) {
      background-color: var(--marketplaceColorLightBg);
    }

    & :global(.CalendarMonthGrid_month__horizontal) {
      /* width: 100% !important; */
      padding: 0px;

      /* @media (--viewportMedium) {
        max-width: calc(100% / 2 - 13px) !important;
      }

      @media (--viewportLargeWithPaddings) {
        max-width: calc(100% / 2 - 24px) !important;
      } */
    }

    /* & :global(.CalendarMonthGrid_month__horizontal:nth-child(2)) {
      margin-right: 24px;

      @media (--viewportLargeWithPaddings) {
        margin-right: 48px;
      }
    } */

    & :global(.DayPickerNavigation_button__default) {
      background-color: var(--marketplaceColorLightBg);
    }

    & :global(.CalendarDay__default) {
      /* background-color: var(--marketplaceColorLightBg);
      color: var(--colorTextDark); */
      border: none;
    }

    & :global(.CalendarDay__default:hover) {
      background-color: var(--marketplaceColorLightBg);
      color: var(--colorText);
    }

    & :global(.CalendarDay__default.CalendarDay__today:hover),
    & :global(.CalendarDay__default.CalendarDay__selected:hover),
    & :global(.CalendarDay__default.CalendarDay__selected_span:hover) {
      background-color: var(--marketplaceColor);
    }

    & :global(.CalendarMonth) {
      /* padding: 0 !important; */
      border-radius: 10px;
      overflow: hidden;
    }

    & :global(.DayPicker) {
      border: none;
      box-shadow: none;
      border-radius: 10px;
      /* width: 100% !important; */
    }

    /* & :global(.DayPicker > div > div) {
      width: 100% !important;
    } */

    /* & :global(.DayPicker_transitionContainer) {
      width: 100% !important;

      @media (--viewportMedium) {
        max-width: 982px !important;
      }
    } */

    & :global(.CalendarMonthGrid__horizontal) {
      /* width: 100% !important; */
      left: 0;
      border-radius: 10px;
    }

    /* & :global(.DayPicker_weekHeaders__horizontal) {
      margin-left: 0;
    } */

    & :global(.DayPicker_weekHeaders) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 70px;
    }

    & :global(.DayPicker_weekHeader) {
      /* width: calc(100% / 2 - 12px) !important;
      left: 0 !important;
      padding: 0 !important; */
      position: relative;
      top: 0;

      /* @media (--viewportLargeWithPaddings) {
        width: calc(100% / 2 - 24px) !important;
      } */
    }

    & :global(.DayPicker_weekHeader_li) {
      /* width: calc(100% / 7) !important; */
      text-align: center;
      padding: 0px 14px;
      font-weight: var(--fontWeightSemiBold);
      color: var(--colorTextDark);
    }

    & :global(.DayPicker_weekHeader__vertical) {
      width: 100%;
      left: 0;
    }

    /* & :global(.CalendarMonth_table) {
      width: 100% !important;
    } */

    & :global(.CalendarMonth_table > tbody > tr > td) {
      /* width: calc(100% / 7) !important; */
      vertical-align: middle;
      text-align: center;
      padding: 4px 14px;
      color: var(--colorTextDark);
      border: none;
      background-color: transparent;
    }

    & :global(.CalendarDay__selected_span) {
      background-color: var(--marketplaceColor) !important;
    }

    & :global(.CalendarDay__hovered_span) {
      background-color: var(--marketplaceColor) !important;
      color: var(--colorText) !important;
    }

    & :global(.CalendarDay__selected_start),
    & :global(.CalendarDay__selected_end) {
      background-color: var(--marketplaceColorDark) !important;
    }

    & :global(.CalendarDay__selected_start),
    & :global(.CalendarDay__selected_end),
    & :global(.CalendarDay__selected_span) {
      color: var(--colorText) !important;
    }

    & :global(.CalendarDay__blocked_out_of_range),
    & :global(.CalendarDay__blocked_out_of_range:active),
    & :global(.CalendarDay__blocked_out_of_range:hover) {
      background-color: transparent;
      color: var(--colorGrey500);
    }

    & :global(.CalendarMonth_caption) {
      padding-bottom: 43px;
      color: var(--colorTextDark);

      @media (--viewportMedium) {
        padding-bottom: 60px;
      }
    }

    & :global(.DateInput) {
      & > input {
        border: none;
        border-radius: 0;
        background-color: var(--colorGrey50);
      }

      & > svg {
        display: none;
      }
    }

    & :global(.DateRangePickerInput_arrow) {
      display: none;
      align-items: center;
      justify-content: center;
    }
  }

  & :global(.CalendarDay__default) {
    border: none;
    background-color: transparent;
    /* width: 39px !important; */
  }

  & :global(.CalendarDay__default:hover) {
    background-color: var(--marketplaceColorLightBg);
    color: var(--colorText);
  }

  /* & :global(.DateInput),
  & :global(.DateRangePickerInput_arrow) {
    display: none;
  }

  & :global(.DateRangePickerInput_clearDates) {
    display: none !important;
  }

  & :global(.DateRangePickerInput__withBorder) {
    border: none;
  } */
  & :global(.DayPicker_transitionContainer) {
    background-color: var(--marketplaceColorLightBg);
  }

  & :global(.DateInput_fang) {
    display: none;
  }

  & :global(.DateRangePicker_picker) {
    margin-top: 0 !important;
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DateRangePickerInput) {
    display: flex;
    position: relative;
    height: 100%;
    background-color: transparent;
    border: none;
  }

  & :global(.CalendarMonth) {
    background-color: var(--marketplaceColorLightBg);
  }

  & :global(.CalendarDay__selected_span) {
    background-color: var(--marketplaceColor) !important;
  }

  & :global(.CalendarDay__hovered_span) {
    background-color: var(--marketplaceColor) !important;
    color: var(--colorText) !important;
  }

  & :global(.CalendarDay__selected_start),
  & :global(.CalendarDay__selected_end) {
    background-color: var(--marketplaceColorDark) !important;
  }

  & :global(.CalendarDay__selected_start),
  & :global(.CalendarDay__selected_end),
  & :global(.CalendarDay__selected_span) {
    color: var(--colorText) !important;
  }

  & :global(.DayPicker__horizontal) {
    background-color: transparent;
  }

  & :global(.DayPicker) {
    background-color: transparent;
  }

  & :global(.DateRangePicker_picker) {
    background-color: transparent;
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DayPicker__withBorder) {
    box-shadow: none;
    margin: 0 auto;
    background-color: transparent;
  }

  & :global(.DayPicker_weekHeader) {
    background-color: transparent !important;
  }

  & :global(.CalendarMonth_caption) {
    padding-bottom: 43px;

    @media (--viewportMedium) {
      padding-bottom: 50px;
    }
  }

  & :global(.DayPickerNavigation__verticalDefault) {
    max-width: 300px;
    margin: 0 auto;
    right: 0;
    left: 0;
    width: 100%;
    bottom: auto;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & :global(.DayPickerNavigation_svg__vertical) {
    width: 16px;
    height: 16px;
  }

  & :global(.DayPickerNavigation_button__verticalDefault) {
    background-color: transparent;
    box-shadow: none;
    /* width: 30px; */
  }

  & :global(.DayPickerNavigation_button__default) {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  & :global(.DayPickerNavigation_svg__horizontal) {
    & path {
      fill: var(--colorBlack);
    }
  }

  & :global(.CalendarDay__default:hover) {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
    cursor: pointer;
  }

  & :global(.CalendarDay__default.CalendarDay__today:hover),
  & :global(.CalendarDay__default.CalendarDay__selected:hover),
  & :global(.CalendarDay__default.CalendarDay__selected_span:hover) {
    background-color: var(--marketplaceColor);
  }

  & :global(.DateRangePicker_picker) {
    position: absolute;
    top: 100%;
    height: 100%;
    background-color: transparent;
  }

  & :global(.DateRangePickerInput__showClearDates) {
    padding-right: 0;
  }
}

.claimByTagline {
  position: relative;

  @media only screen and (max-width: 768px) {
    /* bottom: 0px;
    left: 6px;
    top: 12px; */
  }
}

.claimBtnInline {
  margin-left: 6px;
  text-decoration: underline;
  cursor: pointer;
}
