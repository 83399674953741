@import '../../../styles/customMediaQueries.css';
.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  display: block !important;
  position: relative;
  background-color: var(--marketplaceColorLightBg);
  /* @media (--viewportMedium) {
    padding-bottom: 306px;
  } */
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: block;
  position: relative;
}
.footer {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.listingPageFooter {
  & .main {
    padding-bottom: 0;
  }
}
