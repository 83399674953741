@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-bottom: 80px;
    margin-top: 10px;
  }
  & > img {
    max-width: 100%;
  }
}

.number {
  composes: marketplaceHeroTitleFontStyles from global;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0;
  font-size: 80px;
  line-height: 130%;
  font-weight: var(--fontWeightBlack);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  text-align: center;
  margin-top: 16px;
  color: var(--colorTextDark);

  @media (--viewportMedium) {
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  margin-bottom: 0;
  color: var(--colorText);

  @media (--viewportMedium) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.searchForm {
  max-width: 100%;
  width: 100%;
  margin: 30px auto 0 auto;
  background-color: var(--colorText);
  box-shadow: var(--boxShadowNotFoundPageSearch);
}
.notFoundContent {
  margin-top: -40px;
}
