@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  /* fill */
  background-color: var(--marketplaceColorDark);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px;
  }
}
.rightLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
  gap: 14px;
  & > * {
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}

/* logo */
.logoLink {
  transition: var(--transitionStyleButton);
  flex-shrink: 0;
  margin-right: 24px;
}
.logoLink:hover {
  border-radius: 0;
  text-decoration: none;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: auto;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: 100%;
  max-width: 50px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  display: flex;
  flex-grow: 1;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
  min-width: 200px;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);

  display: inline-block;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }
}

.topbarSearchWithLeftPadding {
  /* display: none; */
  height: 100%;
  & > form {
    width: 100%;
  }
}

.hiddenDiv {
  display: flex;
  flex-grow: 1;
}

/* Create listing (CTA for providers) */
.createListingLink {
  transition: var(--transitionStyleButton);
  background-color: var(--marketplaceColorLight);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  height: 100%;
  padding: 10px 20px;
  color: var(--colorText);
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 100px;
  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    color: var(--colorText);
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }
}

.topbarDesktopLabel {
  display: inline-block;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colorText);

  &:hover {
    color: var(--colorText);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -5px;
  right: 0px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0px;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
  }
}

.profileMenuIsOpen {
  &:hover {
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.navLink {
  transition: var(--transitionStyleButton);
  flex-shrink: 0;
  height: 100%;
  font-size: 14px;
  color: var(--colorText);
  padding: 10px 0;
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }
  & span {
    font-size: 14px;
    @media (--viewportLargeWithPaddings) {
      font-size: 16px;
    }
  }
  &:hover {
    color: var(--colorText);
    border-radius: 0;
    text-decoration: none;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: h4 from global;
  position: relative;
  display: flex;
  align-items: center;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 6px 24px;
  font-size: 14px;
  line-height: 130%;
  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  font-weight: var(--fontWeightMedium);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
  & .notificationDot {
    position: relative;
    right: auto;
    top: auto;
    margin-left: 10px;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);
  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.languageSwitcherWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto 0;
}
.middleButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  min-width: 250px;
  border-radius: 100px;
  & > div {
    & > div {
      & > div {
        &:first-child {
          & > div {
            & input {
              min-height: auto !important;
            }
          }
        }
      }
    }
  }
  & :global(.css-b62m3t-container) {
    width: 100%;
  }
  & :global(.css-13cymwt-control) {
    border: solid 1px var(--colorGrey100);
    border-radius: 100px;
    background-position: center right 10px;
    font-size: 14px;
    line-height: 110%;
    background-size: 12px;
    @media (--viewportLargeWithPaddings) {
      font-size: 16px;
    }
  }
  & :global(.css-qbdosj-Input) {
    margin: 0;
    padding: 0px;
  }
  & :global(.css-19bb58m) {
    margin: 0 !important;
    padding: 0px !important;
  }
  & :global(.css-166bipr-Input) {
    margin: 0;
    padding: 0px;
  }
  & :global(.css-qbdosj-Input > input) {
    min-height: auto !important;
    /* height: 100% !important; */
    box-shadow: none !important;
  }
  & :global(.css-166bipr-Input > input) {
    min-height: auto !important;
    /* height: 100% !important; */
    box-shadow: none !important;
  }
  & :global(.css-166bipr-Input > input:focus) {
    box-shadow: none !important;
    min-height: auto !important;
  }
  & :global(.css-qbdosj-Input > input:focus) {
    box-shadow: none !important;
    /* min-height: auto !important; */
  }
  & :global(.css-qbdosj-Input:after) {
  }
}

.aboutUsButton {
  composes: buttonOnWhiteBackgroundStype from global;

  min-height: 36px;
  /* padding-right: 2px; */
  /* margin-right: 2px; */
  /* margin-top: 4px; */
  height: 40px;
  border-radius: 30px;
  color: #f6fde8;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;

  &:hover {
    text-decoration: none;
  }
}

.addSpotButtonWrapper {
  height: 100%;
  align-items: center;
  display: flex;
  margin: 0;
}

.lookForSpaceButton {
  background-color: transparent;
  color: var(--marketplaceColor);
  margin-right: 10px;
  &:hover {
    background-color: transparent;
    box-shadow: none;
    color: var(--marketplaceColorDark);
    border-radius: 0;
    text-decoration: none;
  }
}

.commonLinkNoStyle {
  all: initial;
}

.staticPageLink {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.desktopLinks {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 24px;
  flex-grow: 0.6;
  justify-content: flex-end;
  @media (--viewportLargeWithPaddings) {
    gap: 20px;
  }
}

.topbarSearch {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  border: solid 1px #cccccc;
  flex-grow: 0.5;
  height: 44px;
  @media (--viewportMedium) {
    padding: 6px 14px 6px 6px;
    background-color: var(--colorText);
    border-radius: 100px;
    flex-direction: row;
  }
  &:hover {
    border-color: hsl(240 3% 80%);
  }
  & .searchLink {
    border-left: none;
    align-items: center;
    justify-content: space-between;

    & .topbarSearchWithLeftPadding {
      flex-grow: 1;
      line-height: 15px;
      font-size: 13px;
      color: #575f60;
      & input {
        min-height: auto;
        height: auto;
        padding: 0;
        font-size: 14px;
        min-width: 150px;
      }
    }
  }
}

.seoOptions {
  width: 94px;
  & select {
    cursor: pointer !important;
    background: #58844c;
    color: #e9f4db;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
    padding: 0;
    height: 40px;
  }
}
