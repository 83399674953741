@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorText);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  box-shadow: var(--boxShadowNotFoundPageSearch);
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  & > div {
    padding-bottom: 66.66% !important;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px;
  cursor: pointer;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBlack);
  font-size: 20px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
  & .title {
    font-weight: var(--fontWeightBlack);
    font-size: 20px;
    color: #51565c;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 20px;
    width: calc(100% - 20px);
  }
  & .flag {
    width: 20px;
    & > img {
      max-width: 100%;
      width: 20px;
      height: auto;
      opacity: 0.8;
    }
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: #51565c;
  font-size: 13px;
  padding: 0;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.amenities {
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  & img {
    width: calc(94% / 7.5);
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.priceSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    margin: 0;
    padding: 0;
    color: var(--colorTextDark);
    font-size: 12.9px;
    line-height: 110%;
  }
  & .price {
    color: var(--marketplaceColorLight);
    font-size: 20.7px;
    font-weight: var(--fontWeightBold);
  }
}

.mobileSection {
  & .info {
    & .mainInfo {
      & .title {
        font-size: 20px;
        @media (--viewportSmall) {
          font-size: 25px;
        }
        @media (--viewportMedium) {
          font-size: 35px;
        }
      }
      & .flag {
        position: relative;
        width: 30px;
        height: auto;
        @media (--viewportMedium) {
          width: 40px;
          height: auto;
        }
        & > img {
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    & .priceSec {
      & > p {
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 20px;
        @media (--viewportSmall) {
          font-size: 20px;
        }
      }
      & .price {
        font-size: 25px;
        @media (--viewportSmall) {
          font-size: 35px;
        }
      }
    }
  }
}

.favorite {
  text-align: end;
  margin-right: 12px;
  cursor: pointer;
  margin-top: 10px;
  & > svg {
    width: 22px;
    height: 22px;
    fill: #9cc78f;
    & > path {
      stroke: #9cc78f;
    }
  }
}
.imgPills {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  & > span {
    background-color: var(--colorText);
    color: var(--colorTextDark);
    font-size: 14px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 100px;
  }
}

.pills {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
  & > span {
    background-color: var(--marketplaceColorLight);
    color: var(--colorText);
    font-size: 14px;
    line-height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 100px;
  }
}
