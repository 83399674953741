@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & .mobileNav {
    line-height: 100%;
    & .mobileNavLink {
      font-size: 20px;
      color: var(--colorTextDark);
      font-weight: var(--fontWeightBold);
      line-height: 120%;
      width: 100%;
      display: inline-flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
      &.loginLink {
        & > svg {
          margin-left: -2px;
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
      }

      &.campingIcon {
        & > svg {
          width: 28px;
          height: 28px;
        }
        & > div {
          & input {
            min-height: auto;
            height: auto;
          }
        }
      }
      &.blogIcon {
        & > svg {
          width: 22px;
          height: 22px;
        }
      }
      & > svg {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      & :global(.css-b62m3t-container) {
        width: 100%;
      }
      & :global(.css-z0qqkp-control) {
        border: none;
      }
      & :global(.css-1jvrolk-control) {
        border: none;
      }
      & :global(.css-13cymwt-control) {
        border: none;
        border-radius: 100px;
        background-position: center right 10px;
        font-size: 20px;
        color: var(--colorTextDark);
        font-weight: var(--fontWeightBold);
        line-height: 120%;
        background-size: 12px;
      }
      & :global(.css-1j471u5-ValueContainer) {
        padding: 0;
      }
      & :global(.css-1jqq78o-placeholder) {
        font-size: 20px;
        color: var(--colorTextDark);
        font-weight: var(--fontWeightBold);
        line-height: 120%;
      }
      & :global(.css-qbdosj-Input) {
        margin: 0;
        padding: 0;
        font-size: 20px;
        color: var(--colorTextDark);
        font-weight: var(--fontWeightBold);
        line-height: 120%;
      }
      & :global(.css-166bipr-Input) {
        margin: 0;
        padding: 0;
      }
      & :global(.css-qbdosj-Input > input) {
        min-height: auto !important;
        height: 100% !important;
        box-shadow: none !important;
      }
      & :global(.css-166bipr-Input > input) {
        min-height: auto !important;
        height: 100% !important;
        box-shadow: none !important;
      }
      & :global(.css-166bipr-Input > input:focus) {
        box-shadow: none !important;
        min-height: auto !important;
      }
      & :global(.css-qbdosj-Input > input:focus) {
        box-shadow: none !important;
        min-height: auto !important;
      }
      & :global(.css-qbdosj-Input:after) {
      }
    }
  }
}
.separator {
  display: block;
  margin: 14px 0;
  width: 100%;
  height: 2px;
  background-color: var(--marketplaceColorDark);
}
.otherLinks {
  display: flex;
  flex-direction: column;
  & .otherLink {
    font-size: 20px;
    color: var(--colorTextDark);
    font-weight: var(--fontWeightRegular);
    line-height: 120%;
    text-align: left;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  background-color: var(--colorText);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
}
.greetingSec {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & .logo {
    & > div {
      width: 60px;
      height: 60px;
      position: relative;
      & > img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 60px;
        height: 60px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.greeting {
  font-weight: var(--fontWeightBlack);
  color: var(--marketplaceColor);
  font-size: 25px;
  line-height: 140%;
  padding-left: 20px;

  @media (--viewportMedium) {
    font-size: 35px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 120%;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 40px;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h2 from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.bottomContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 14px;
  & > div {
    margin-bottom: 5px;
    width: auto;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > div {
      & > div {
        width: auto !important;
        padding: 10px;
        bottom: 100%;
        & > div {
          display: none;
        }
      }
    }
  }
}

.staticPageLinks {
  display: flex;
  align-items: center;
  position: relative;
  & > a {
    font-size: 15px;
    font-weight: var(--fontWeightRegular);
    color: var(--colorTextDark);
    position: relative;
    &:not(:last-child) {
      margin-right: 5px;

      &::after {
        content: '.';
        font-size: 15px;
        font-weight: var(--fontWeightBlack);
        line-height: 100%;
        position: relative;
        top: -2px;
        color: var(--colorTextDark);
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 10px;

  /* Position */
  position: relative;

  /* Style: red dot */
  background-color: var(--colorFail);
}
