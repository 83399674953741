.root {
}

.subLabel {
  color: var(--colorTextDark);
  font-family: var(--fontFamily);
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  margin-bottom: 10px;
  padding: 0;
}
