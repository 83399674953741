@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);
  padding: 15px 24px;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--marketplaceColorDark);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }

  & .leftSec {
    display: flex;
    align-items: center;
  }

  & .logo {
    margin: 0;
    height: auto;
    padding-left: 20px;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 30px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }

  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  /* padding: 15px 24px; */
  position: relative;

  /* @media (--viewportMedium) {
    padding: 21px 24px;
  } */
}

.searchMenu {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 6px;
  right: 6px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorText);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorText);
}

.rootSearchIcon {
  stroke: var(--colorText);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
  padding: 0px 24px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);
  position: relative;

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: 100vh;
    height: 100%;
    padding: 24px;
    background-color: var(--colorText);
    border-bottom: none;
  }
}

.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;

  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorText);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.filterModal {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);
  position: relative;
  padding: 24px;
  background-color: var(--marketplaceColorLightBg);
  border-bottom: none;
  width: 100%;
  height: 100%;
  flex-basis: 100%;
  padding-bottom: 90px;

  @media (--viewportMedium) {
    padding: 24px 70px;
    transform: translateZ(10px);
  }

  @media (--viewportLarge) {
    padding: 24px 100px;
    flex-basis: 576px;
    flex-grow: 1;
    min-height: 50vh;
    height: 80%;
  }

  @media (min-width: 1500px) {
    height: 70%;
  }
}

.locationFilterWrapper {
  display: flex;
  padding: 24px 0;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportXLarge) {
    padding: 60px 0;
  }

  & > * {
    width: 100%;
    margin-bottom: 24px;

    @media (--viewportMedium) {
      width: calc(100% / 3);
      margin-bottom: 24px;
    }

    & h4 {
      margin: 0 0 22px 0;
      padding: 0;
      font-size: 20px;
      line-height: 110%;
      font-weight: var(--fontWeightBold);
      color: var(--marketplaceColorDark);
    }
  }

  & .locationPredictions {
    & > div {
      position: relative;
      top: 0;
      width: 85%;
      padding: 0 20px 0 0;
      overflow-y: auto;
      max-height: 200px;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--colorText);
        border-radius: 100px;
        padding: 2px;
      }

      &::-webkit-scrollbar-thumb {
        width: 2px;
        height: 20px;
        border-radius: 100px;
        background-color: var(--marketplaceColor);
      }

      & > ul {
        padding: 0;
      }
    }
  }

  & .otherLinks {
    display: flex;
    flex-direction: column;

    @media (--viewportMedium) {
      padding: 0 24px;
    }

    & .filterBlock {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 200px;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--colorText);
        border-radius: 100px;
        padding: 2px;
      }

      &::-webkit-scrollbar-thumb {
        width: 2px;
        height: 20px;
        border-radius: 100px;
        background-color: var(--marketplaceColor);
      }

      & > a {
        display: inline-flex;
        align-items: center;
        width: 100%;
        color: var(--colorTextDark);
        font-size: 15px;
        font-weight: var(--fontWeightBold);

        &:not(:last-child) {
          margin-bottom: 10px;

          @media (--viewportLarge) {
            margin-bottom: 22px;
          }
        }

        &:hover {
          text-decoration: none;
          color: var(--colorText);
        }
      }

      & > span {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        color: #ffffff;
        font-size: 15px;
        font-weight: var(--fontWeightBold);
        word-break: break-word;
        &:not(:last-child) {
          margin-bottom: 10px;

          @media (--viewportLarge) {
            margin-bottom: 22px;
          }
        }

        &:hover {
          text-decoration: none;
          color: var(--marketplaceColorDark);
        }
      }
    }
  }
}

.customFilters {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  padding: 24px 0;
  width: 100%;

  @media (--viewportMedium) {
    overflow-y: auto;
    max-height: 340px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    padding: 60px 0;
    grid-gap: 30px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--colorText);
    border-radius: 100px;
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    height: 20px;
    border-radius: 100px;
    background-color: var(--marketplaceColor);
  }

  & .filterBlock {
    & h4 {
      margin: 0 0 22px 0;
      padding: 0;
      font-size: 20px;
      line-height: 110%;
      font-weight: var(--fontWeightBold);
      color: var(--marketplaceColorDark);

      & .toggleIcon {
        margin-right: 10px;
        cursor: pointer;
        transition: var(--transitionStyle);
        transform: scale(1);

        &:hover {
          transition: var(--transitionStyle);
          transform: scale(1.5);
        }

        & > svg {
          stroke: var(--colorText);
          fill: none;
        }
      }
    }

    & .options {
      background-color: transparent;
      max-height: 200px;
      overflow-y: auto;
      padding: 0 20px 0 0;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--colorText);
        padding: 2px;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        width: 2px;
        height: 20px;
        border-radius: 100px;
        background-color: var(--marketplaceColor);
      }

      & .filterOption {
        display: flex;
        cursor: pointer;

        & > label {
          font-size: 15px;
          font-weight: var(--fontWeightBold);
          color: var(--colorText);

          &:hover {
            cursor: pointer;
            color: var(--marketplaceColorDark);
          }
        }

        & > input {
          width: 20px;
          position: relative;
          bottom: 15px;
          margin-right: 10px;
        }
      }
    }
  }
}

.filterSubmit {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-end;
  flex-direction: row;
  position: fixed;
  padding: 10px 24px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;

  background-color: var(--colorText);
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    box-shadow: none;
    background-color: var(--marketplaceColorLightBg);
    justify-content: flex-end;
    flex-direction: column;
    padding: 0px 24px 15px 24px;
  }

  & > button {
    width: auto;
    align-self: flex-end;
    font-size: 15px;
    font-weight: var(--fontWeightBold);
    padding: 10px 20px;
    min-height: auto;
    height: auto;

    @media (--viewportMedium) {
      min-width: 200px;
    }

    &:not(:last-child) {
      @media (--viewportMedium) {
        margin-bottom: 5px;
      }
    }

    &.applyBtn {
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
      order: 2;

      @media (--viewportMedium) {
        order: 1;
      }

      &:hover {
        background-color: var(--marketplaceColor);
        border-color: var(--marketplaceColor);
        color: var(--colorText);
      }
    }

    &.resetBtn {
      background-color: transparent;
      box-shadow: none;
      color: var(--colorTextDark);
      min-width: auto;
      border: none;
      order: 1;

      @media (--viewportMedium) {
        min-width: 200px;
        order: 2;
      }

      &:hover {
        border: none;
        color: var(--colorText);
      }
    }
  }
}

.filterModalContainer {
  & .filterNav {
    background-color: var(--colorText);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    overflow: hidden;
    text-align: center;
    border-radius: 100px;

    & .filterNavBtn {
      font-size: 12px;
      font-weight: var(--fontWeightBold);
      color: var(--marketplaceColorDark);
      border-radius: 0px;
      padding: 6px 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: var(--transitionStyle);
      flex-direction: column;

      @media (--viewportSmall) {
        padding: 15px 20px;
        flex-direction: row;
      }

      & svg {
        @media (--viewportSmall) {
          margin-right: 5px;
        }

        @media (--viewportMedium) {
          margin-right: 8px;
        }
      }

      &:hover {
        background-color: var(--marketplaceColor);
        color: var(--colorText);
        transition: var(--transitionStyle);

        & > svg {
          & path {
            fill: var(--colorText);
          }
        }
      }

      &.active {
        background-color: var(--marketplaceColorDark);
        color: var(--colorText);
        transition: var(--transitionStyle);

        & > svg {
          & path {
            fill: var(--colorText);
          }
        }
      }

      &.locationIcon {
        & > svg {
          fill: none;

          & path {
            fill: none;
            stroke: var(--marketplaceColorDark);
          }
        }

        &:hover,
        &.active {
          & > svg {
            fill: none;

            & path {
              fill: none;
              stroke: var(--colorText);
            }
          }
        }
      }

      &.filterIcon {
        & > svg {
          width: 24px;
          height: 24px;
        }

        &:hover,
        &.active {
          & > svg {
            width: 24px;
            height: 24px;
            fill: none;

            & path {
              stroke: var(--colorText);
              fill: none;
            }

            & circle {
              stroke: var(--colorText);
            }
          }
        }

        & > svg {
          fill: none;

          & path {
            fill: none;
            stroke: var(--marketplaceColorDark);
          }

          & circle {
            stroke: var(--marketplaceColorDark);
          }
        }
      }
    }
  }
}

.availablityCalendar {
  padding: 24px 0;
  width: 100%;

  @media (--viewportMedium) {
    width: calc(100% - 300px);
  }

  @media (--viewportXLarge) {
    padding: 60px 0;
  }

  @media (--viewportLarge) {
    & :global(.DateRangePicker) {
      width: 100%;
      position: relative;
      height: 100%;
    }

    & :global(.DateRangePicker > div) {
      height: 100%;
    }

    & :global(.DayPickerNavigation_button__verticalDefault) {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      & > svg {
        width: 20px;
        height: 20px;
      }
    }

    & :global(.DateRangePickerInput) {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: transparent;
      border: none;
    }

    & :global(.DateRangePicker_picker) {
      position: relative;
      top: 0 !important;
      order: 1;
      height: 100%;
      background-color: transparent;
    }

    & :global(.CalendarMonthGrid) {
      background-color: transparent;
    }

    & :global(.DayPicker__horizontal) {
      background-color: transparent;
    }

    & :global(.DayPicker__withBorder) {
      box-shadow: none;
      background-color: transparent;
    }

    & :global(.CalendarMonth) {
      background-color: var(--marketplaceColorLightBg);
    }

    & :global(.CalendarMonthGrid_month__horizontal) {
      /* width: 100% !important; */
      padding: 0px;

      /* @media (--viewportMedium) {
        max-width: calc(100% / 2 - 13px) !important;
      }

      @media (--viewportLargeWithPaddings) {
        max-width: calc(100% / 2 - 24px) !important;
      } */
    }

    /* & :global(.CalendarMonthGrid_month__horizontal:nth-child(2)) {
      margin-right: 24px;

      @media (--viewportLargeWithPaddings) {
        margin-right: 48px;
      }
    } */

    & :global(.DayPickerNavigation_button__default) {
      background-color: var(--marketplaceColorLightBg);
    }

    & :global(.CalendarDay__default) {
      /* background-color: var(--marketplaceColorLightBg);
      color: var(--colorTextDark); */
      border: none;
    }

    & :global(.CalendarDay__default:hover) {
      background-color: var(--marketplaceColorLightBg);
      color: var(--colorText);
    }

    & :global(.CalendarDay__default.CalendarDay__today:hover),
    & :global(.CalendarDay__default.CalendarDay__selected:hover),
    & :global(.CalendarDay__default.CalendarDay__selected_span:hover) {
      background-color: var(--marketplaceColor);
    }

    & :global(.CalendarMonth) {
      /* padding: 0 !important; */
      border-radius: 10px;
      overflow: hidden;
    }

    & :global(.DayPicker) {
      border: none;
      box-shadow: none;
      border-radius: 10px;
      /* width: 100% !important; */
    }

    /* & :global(.DayPicker > div > div) {
      width: 100% !important;
    } */

    /* & :global(.DayPicker_transitionContainer) {
      width: 100% !important;

      @media (--viewportMedium) {
        max-width: 982px !important;
      }
    } */

    & :global(.CalendarMonthGrid__horizontal) {
      /* width: 100% !important; */
      left: 0;
      border-radius: 10px;
    }

    /* & :global(.DayPicker_weekHeaders__horizontal) {
      margin-left: 0;
    } */

    & :global(.DayPicker_weekHeaders) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      top: 70px;
    }

    & :global(.DayPicker_weekHeader) {
      /* width: calc(100% / 2 - 12px) !important;
      left: 0 !important;
      padding: 0 !important; */
      /* position: relative; */
      top: 0;

      /* @media (--viewportLargeWithPaddings) {
        width: calc(100% / 2 - 24px) !important;
      } */
    }

    & :global(.DayPicker_weekHeader_li) {
      /* width: calc(100% / 7) !important; */
      text-align: center;
      padding: 0px 14px;
      font-weight: var(--fontWeightSemiBold);
      color: var(--colorTextDark);
    }

    & :global(.DayPicker_weekHeader__vertical) {
      width: 100%;
      left: 0;
    }

    /* & :global(.CalendarMonth_table) {
      width: 100% !important;
    } */

    & :global(.CalendarMonth_table > tbody > tr > td) {
      /* width: calc(100% / 7) !important; */
      vertical-align: middle;
      text-align: center;
      padding: 4px 14px;
      color: var(--colorTextDark);
      border: none;
      background-color: transparent;
    }

    & :global(.CalendarDay__selected_span) {
      background-color: var(--marketplaceColor) !important;
    }

    & :global(.CalendarDay__hovered_span) {
      background-color: var(--marketplaceColor) !important;
      color: var(--colorText) !important;
    }

    & :global(.CalendarDay__selected_start),
    & :global(.CalendarDay__selected_end) {
      background-color: var(--marketplaceColorDark) !important;
    }

    & :global(.CalendarDay__selected_start),
    & :global(.CalendarDay__selected_end),
    & :global(.CalendarDay__selected_span) {
      color: var(--colorText) !important;
    }

    & :global(.CalendarDay__blocked_out_of_range),
    & :global(.CalendarDay__blocked_out_of_range:active),
    & :global(.CalendarDay__blocked_out_of_range:hover) {
      background-color: transparent;
      color: var(--colorGrey500);
    }

    & :global(.CalendarMonth_caption) {
      padding-bottom: 43px;
      color: var(--colorTextDark);

      @media (--viewportMedium) {
        padding-bottom: 60px;
      }
    }

    & :global(.DateInput) {
      position: absolute;
      top: 100%;
      order: 4;
      left: 50%;

      & > input {
        border: none;
        border-radius: 0;
        background-color: var(--colorGrey50);
      }

      & > svg {
        display: none;
      }

      &:first-of-type {
        left: 0;
        order: 2;
      }
    }

    & :global(.DateRangePickerInput_arrow) {
      order: 3;
      width: 50px;
      left: 30%;
      position: absolute;
      top: 100%;
      height: 50px;
      display: none;
      align-items: center;
      justify-content: center;
    }
  }

  & :global(.CalendarDay__default) {
    border: none;
    background-color: transparent;
    /* width: 39px !important; */
  }

  & :global(.CalendarDay__default:hover) {
    background-color: var(--marketplaceColorLightBg);
    color: var(--colorText);
  }

  & :global(.DateInput),
  & :global(.DateRangePickerInput_arrow) {
    display: none;
  }

  & :global(.DateRangePickerInput_clearDates) {
    display: none !important;
  }

  & :global(.DateRangePickerInput__withBorder) {
    border: none;
  }

  & :global(.DateRangePicker_picker) {
    margin-top: 0 !important;
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DateRangePickerInput) {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: transparent;
    border: none;
  }

  & :global(.CalendarMonth) {
    background-color: var(--marketplaceColorLightBg);
  }

  & :global(.CalendarDay__selected_span) {
    background-color: var(--marketplaceColor) !important;
  }

  & :global(.CalendarDay__hovered_span) {
    background-color: var(--marketplaceColor) !important;
    color: var(--colorText) !important;
  }

  & :global(.CalendarDay__selected_start),
  & :global(.CalendarDay__selected_end) {
    background-color: var(--marketplaceColorDark) !important;
  }

  & :global(.CalendarDay__selected_start),
  & :global(.CalendarDay__selected_end),
  & :global(.CalendarDay__selected_span) {
    color: var(--colorText) !important;
  }

  & :global(.DayPicker__horizontal) {
    background-color: transparent;
  }

  & :global(.DayPicker) {
    background-color: transparent;
  }

  & :global(.DateRangePicker_picker) {
    background-color: transparent;
  }

  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }

  & :global(.DayPicker__withBorder) {
    box-shadow: none;
    margin: 0 auto;
    background-color: transparent;
  }

  & :global(.DayPicker_weekHeader) {
    background-color: transparent !important;
  }

  & :global(.CalendarMonth_caption) {
    padding-bottom: 43px;

    @media (--viewportMedium) {
      padding-bottom: 50px;
    }
  }

  & :global(.DayPickerNavigation__verticalDefault) {
    max-width: 300px;
    margin: 0 auto;
    right: 0;
    left: 0;
    width: 100%;
    bottom: auto;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & :global(.DayPickerNavigation_svg__vertical) {
    width: 16px;
    height: 16px;
  }

  & :global(.DayPickerNavigation_button__verticalDefault) {
    background-color: transparent;
    box-shadow: none;
    width: 30px;
  }

  & :global(.DayPickerNavigation_button__default) {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  & :global(.DayPickerNavigation_svg__horizontal) {
    & path {
      fill: var(--colorBlack);
    }
  }

  & :global(.CalendarDay__default:hover) {
    background-color: var(--marketplaceColor);
    color: var(--colorText);
    cursor: pointer;
  }

  & :global(.CalendarDay__default.CalendarDay__today:hover),
  & :global(.CalendarDay__default.CalendarDay__selected:hover),
  & :global(.CalendarDay__default.CalendarDay__selected_span:hover) {
    background-color: var(--marketplaceColor);
  }

  & :global(.DateRangePicker_picker) {
    position: relative;
    top: 0 !important;
    order: 1;
    height: 100%;
    background-color: transparent;
  }

  & :global(.DateRangePickerInput__showClearDates) {
    padding-right: 0;
  }
}

.topbarSearch {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border: solid 1px var(--colorText);
  padding: 6px 14px 6px 6px;
  background-color: var(--colorText);
  border-radius: 100px;
  flex-grow: 0.5;
  height: 44px;

  @media (max-width: 767px) {
    flex-grow: 1;
    /* padding: 6px 6px 6px 13px; */
    border: none;
    background-color: transparent;
    padding: 0;
    height: auto;
    display: flex;
    flex-direction: row;
  }

  @media (--viewportMedium) {
    flex-direction: row;
  }

  &:hover {
    border-color: hsl(240 3% 80%);
  }

  & .searchLink {
    border-left: none;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .topbarSearchWithLeftPadding {
      flex-grow: 1;
      line-height: 15px;

      @media (max-width: 767px) {
        height: 36px;
        border-radius: 100px;
        border: solid 1px var(--colorText);
        padding: 6px 6px 6px 13px;
        background-color: var(--colorText);
      }

      & input {
        min-height: auto;
        height: auto;
        padding: 0;
      }
    }
  }
}
.priceRange {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .pills {
    background-color: transparent;
    border: solid 1px var(--colorText);
    padding: 10px 20px;
    border-radius: 100px;
    color: var(--colorText);
    font-size: 15px;
    font-weight: var(--fontWeightBold);
    line-height: 100%;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: var(--transitionStyle);

    &:hover {
      cursor: pointer;
      transition: var(--transitionStyle);
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
      border-color: var(--marketplaceColorDark);
    }
  }
}

.optionSelected {
  color: var(--colorText) !important;
}

.pillSelected {
  background: #f6fde8 !important;
  color: #9cc78f !important;
  border-color: #f6fde8 !important;
}

input[type='checkbox'] {
  accent-color: #51565c;
}
.focusInput {
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  font-size: 15px;
  font-weight: var(--fontWeightBold);
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorDark);
  }
}
