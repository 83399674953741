.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  /* background-color: #3d6329 !important; */
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* -webkit-tap-highlight-color: #3d6329 !important; */
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0 0 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0 0 5px 5px #0099e0;
}

:root {
  --scrollbar-size: 6px;
}

.rhc-theme-light {
  --text-primary: #f6fde8;
  --text-secondary: #f6fde8;
  --background: #6fa360;
  --scrollbar-background-color: #d4d4d4;
  --scrollbar-foreground-color: #8a8a8a;
}

.rhc-theme-dark {
  --text-primary: #f6fde8;
  --text-secondary: #f6fde8;
  --background: #6fa360;
  --scrollbar-background-color: #363636;
  --scrollbar-foreground-color: #8a8a8a;
}

.rhc-banner {
  display: block;
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.25rem 0;
  transition: all 0.3s ease;
  background-color: var(--background);
  @media (max-width: 767px) {
    padding: 1.2rem 0;
  }
}

.rhc-banner__content {
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 2.5rem;
}

@media (min-width: 56.25rem) {
  .rhc-banner__content {
    display: flex;
    gap: 0.75rem;
  }
}

.rhc-banner__content__message {
  margin: 0 auto 1rem;
  transition: all 0.3s ease;
  color: var(--text-primary);
}

@media (min-width: 56.25rem) {
  .rhc-banner__content__message {
    margin: 0 auto;
  }
}

.rhc-banner__content__message a {
  transition: all 0.3s ease;
  color: var(--text-primary);
  font-weight: 700;
  text-decoration: none;
}

.rhc-banner__content__secondary {
  width: 100%;
  height: 2.25rem;
  margin: 0.25rem auto;
  padding: 0.125rem;
  transition: all 0.3s ease;
  border: 0.125rem solid var(--text-primary);
  border-radius: 1.125rem;
  background-color: var(--background);
  color: var(--text-primary);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

@media (min-width: 56.25rem) {
  .rhc-banner__content__secondary {
    width: 12.5rem;
    margin: 0 auto;
  }
}

.rhc-banner__content__secondary:hover {
  border-color: var(--text-secondary);
  color: var(--text-secondary);
}

.rhc-banner__content__primary {
  width: 100%;
  height: 2.25rem;
  margin: 0.25rem auto;
  padding: 0.125rem;
  transition: all 0.3s ease;
  border: 0.125rem solid var(--text-primary);
  border-radius: 1.125rem;
  background-color: var(--text-primary);
  color: var(--background);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

@media (min-width: 56.25rem) {
  .rhc-banner__content__primary {
    width: 12.5rem;
    margin: 0 auto;
  }
}

.rhc-banner__content__primary:hover {
  border-color: var(--text-secondary);
  background-color: var(--text-secondary);
}

.rhc-settings {
  display: flex;
  position: fixed;
  z-index: 99999;
  inset: 0;
  align-items: center;
  justify-content: center;
}

.rhc-settings__content {
  display: grid;
  box-sizing: border-box;
  grid-template-rows: min-content auto min-content;
  width: calc(100% - 0.75rem);
  max-width: 40rem;
  height: calc(100% - 0.75rem);
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  background-color: var(--background);
  box-shadow: 0 0.6875rem 2rem #0000001a, 0 0.3125rem 0.75rem -px(8) #0003, 0 0 0.0625rem #0003;
  color: var(--text-primary);
}

@media (min-width: 37.5rem) {
  .rhc-settings__content {
    width: calc(100% - 1.5rem);
    height: calc(100% - 1.5rem);
  }
}

@media (min-width: 56.25rem) {
  .rhc-settings__content {
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
  }
}

.rhc-settings__content__header {
  padding: 1.5rem;
}

.rhc-settings__content__header h1 {
  margin: 0 0 0.75rem;
  font-size: medium;
}

.rhc-settings__content__main {
  padding: 1.5rem;
  overflow-y: auto;
  transition: all 0.3s ease;
  background: #f6fde8;
  color: black;
  scrollbar-color: var(--scrollbar-foreground-color) var(--scrollbar-background-color);
}

.rhc-settings__content__main::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
}

.rhc-settings__content__main::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-size);
  background: var(--scrollbar-foreground-color);
}

.rhc-settings__content__main::-webkit-scrollbar-track {
  border-radius: var(--scrollbar-size);
  background: var(--scrollbar-background-color);
}

.rhc-settings__content__main__item {
  margin: 1.5rem 0;
}

@media (min-width: 56.25rem) {
  .rhc-settings__content__main__item {
    margin: 2.25rem 0;
  }
}

.rhc-settings__content__main__item:first-of-type {
  margin-top: 0;
}

.rhc-settings__content__main__item__title {
  display: flex;
  justify-content: space-between;
}

.rhc-settings__content__main__item__title__label {
  font-weight: 700;
}

.rhc-settings__content__main__item__description {
  margin: 0.5rem 0;
}

.rhc-settings__content__footer {
  display: grid;
  padding: 1.5rem;
  gap: 0.75rem;
}

@media (min-width: 56.25rem) {
  .rhc-settings__content__footer {
    grid-template-columns: 1fr 1fr 1.5fr;
  }
}

.rhc-settings__content__footer__secondary {
  width: 100%;
  height: 2.25rem;
  margin: 0 auto;
  padding: 0.125rem;
  transition: all 0.3s ease;
  border: 0.125rem solid var(--text-primary);
  border-radius: 1.125rem;
  background-color: var(--background);
  color: var(--text-primary);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

.rhc-settings__content__footer__secondary:hover {
  border-color: var(--text-secondary);
  color: var(--text-secondary);
}

.rhc-settings__content__footer__primary {
  width: 100%;
  height: 2.25rem;
  margin: 0 auto;
  padding: 0.125rem;
  transition: all 0.3s ease;
  border: 0.125rem solid var(--text-primary);
  border-radius: 1.125rem;
  background-color: var(--text-primary);
  color: var(--background);
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
}

.rhc-settings__content__footer__primary:hover {
  border-color: var(--text-secondary);
  background-color: var(--text-secondary);
}

/*# sourceMappingURL=style.css.map */
