@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorTextDark);
  padding-bottom: 15px;
  padding-top: 15px;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center;  */
  /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--colorText);
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
    line-height: 24px;

    height: unset;
    border-bottom-width: 0px;
    margin-top: 0;

    padding-top: 8px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 8px;

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center; */
    }
  }
  @media (--viewportLarge) {
    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 5px;
    font-size: 20px;
    margin-bottom: 16px;
    width: var(--TabNav_linkWidth);
  }
}

.selectedLink {
  border-bottom-color: var(--colorText);
  color: var(--colorText);

  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center; */

    &:hover {
      /* background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%234a4a4a' fill-rule='evenodd'/></svg>");
      background-position: right center; */
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

.editlistingTabs {
  position: relative;
  &.selectedLink,
  &:hover {
    color: var(--colorTextDark);
  }
  &.selectedLink {
    &::before {
      color: var(--colorText);
      background-color: var(--colorTextDark);
    }
  }
  &::before {
    counter-increment: section;
    content: counter(section);
    font-size: 16px;
    line-height: 98%;
    font-weight: var(--fontWeightRegular);
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorTextDark);
    border-radius: 100px;
    color: var(--colorText);
    margin-right: 6px;
    @media (--viewportLarge) {
      font-size: 20px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  &.disabled {
    &::before {
      color: var(--colorText);
      background-color: var(--colorGrey300);
    }
  }
}
