@import '../../styles/customMediaQueries.css';

.root {
  composes: buttonDefault from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.primaryButtonRoot {
  composes: buttonPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.primaryButtonInlineRoot {
  composes: buttonPrimaryInline from global;
}

.secondaryButtonRoot {
  composes: buttonSecondary from global;

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
  padding: 0 0 2px 0;
}

.secondaryButtonInlineRoot {
  composes: buttonSecondaryInline from global;

  margin: 17px 0 0 0;
}

.inlineTextButtonRoot {
  composes: a textSmall from global;
}

.primaryButton {
  /* Class handle for primary button state styles */
}
.secondaryButton {
  /* Class handle for secondary button state styles */
}
.secondaryButtonInline {
  /* Class handle for secondary button state styles */
}

.inProgress {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.inProgress:disabled {
  background-color: var(--marketplaceColorDark);
}

.secondaryButton.inProgress:disabled,
.secondaryButtonInline.inProgress:disabled {
  background-color: var(--colorText);
}

.ready {
  padding: 0;

  &:disabled {
    background-color: var(--marketplaceColor);
  }
}

.primaryButton.ready:disabled {
  background-color: var(--marketplaceColorDark);
}

.secondaryButton.ready:disabled,
.secondaryButtonInline.ready:disabled {
  background-color: var(--colorText);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke: var(--colorText);
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;
  stroke: var(--colorText);

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner,
.secondaryButtonInline .spinner {
  stroke: var(--colorGrey300);
}

.secondaryButton .checkmark,
.secondaryButtonInline .checkmark {
  stroke: var(--colorGrey300);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  display: block;
  width: 100%;
  min-height: 48px;
  margin: 0;

  padding: 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;

  text-align: center;
  text-decoration: none;

  border: 1px solid #d2d2d2;
  border-radius: var(--borderRadiusMedium);

  background-color: var(--colorText);
  color: var(--colorBlack);

  /* We need to add this to position the icon inside button */
  position: relative;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: var(--boxShadowButton);
    background-color: var(--colorText);
    text-decoration: none;
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    background-color: var(--colorGrey100);
    color: var(--colorText);
  }

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    padding: 0;
  }
}

.socialButton {
}
