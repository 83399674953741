@import '../../styles/customMediaQueries.css';

.inputLabel {
  font-weight: var(--fontWeightSemiBold);
  margin: 20px 0 0 0;
  line-height: 16px;
  font-size: 14px;
}

.error {
  color: red;
  line-height: 22px;
}

.latLngInput {
}

.mapSection {
  margin-top: 24px;
}

& :global(.mapboxgl-ctrl-bottom-right) {
  & > div {
    & > button {
      display: none;
    }
  }
}

& :global(.mapboxgl-map) {
  margin-bottom: 24px;
}

& :global(.mapboxgl-ctrl) {
  display: flex;
  align-items: center;
  box-shadow: none;
  margin-bottom: 24px;
  /* width: 100%;
  max-width: 100%; */
}

& :global(.mapboxgl-marker > svg > path) {
  fill: var(--marketplaceColorDark);
}

& :global(.mapboxgl-ctrl-geocoder--icon-search) {
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

& :global(.mapboxgl-ctrl-geocoder--input) {
  padding-left: 45px;
  padding-right: 45px;
  border: solid 1px var(--colorGrey100);
}

.formRow {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }

  & .formFld {
    width: 100%;
    margin-bottom: 24px;

    @media (--viewportSmall) {
      width: calc(100% / 2 - 12px);
    }

    &:not(:last-child) {
      @media (--viewportSmall) {
        margin-right: 24px;
      }
    }

    & > label {
      font-size: 15px;
      color: var(--colorGrey600);
      padding: 0;
      margin-bottom: 8px;
      margin-top: 0;
    }
  }
}

.geocoder {
  position: relative;
  z-index: 1;

  & :global(.mapboxgl-ctrl-geocoder) {
    position: relative;
  }

  & :global(.mapboxgl-ctrl-geocoder--icon-search) {
    position: absolute;
    left: 14px;
    top: 24px;
    height: 24px;
    width: 24px;
  }

  & :global(.mapboxgl-ctrl-geocoder--pin-right) {
    position: absolute;
    right: 24px;
    top: 14px;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: end;

    & > svg {
      display: none;
    }
  }

  & :global(.mapboxgl-ctrl-geocoder--button) {
    border: none;
    padding: 0;
    cursor: pointer;
  }

  & :global(.mapboxgl-ctrl-geocoder--button > svg) {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  & :global(.mapboxgl-ctrl-geocoder--icon-loading) {
    width: 20px;
    height: 20px;
  }

  & :global(.suggestions-wrapper) {
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    z-index: 100;
    background-color: var(--colorText);
    box-shadow: var(--boxShadowBreakdown);
  }

  & :global(.suggestions) {
    background-color: var(--colorText);

    & > li,
    & > div {
      padding: 0;

      & > a {
        padding: 6px 24px;
        display: inline-flex;
        width: 100%;
        align-items: center;
        color: var(--colorTextDark);
        font-weight: var(--fontWeightMedium);

        &:hover {
          color: var(--marketplaceColorDark);
          text-decoration: none;
        }

        & > div {
          display: flex;
          width: 100%;
          flex-wrap: wrap;

          & > div {
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

:global(.mapboxgl-map) {
  & canvas {
    width: 100% !important;
  }
}

.mapContainer {
  width: 100%;
  height: 400px;
  margin-bottom: 24px;
}
