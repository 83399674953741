.backgroundLight {
  stop-color: var(--colorBannedLight);
}

.backgroundDark {
  stop-color: var(--colorBannedDark);
}

.foregroundFill {
  fill: var(--colorText);
}

.foregroundStroke {
  stroke: var(--colorText);
}
