@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
}

.error {
  color: var(--colorFail);
  padding-top: 10px;
  margin-left: 4px;
  font-weight: 600;
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 0;
  }
}

.email {
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  flex-direction: column;
  margin-bottom: 10px;

  & > div {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    & > div {
      width: 46%;
      margin-bottom: 24px;
    }
  }

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 6px;
    flex-direction: row;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.newsletter {
  & .newsletterText {
    margin-bottom: 14px;
  }
}

.checkboxGroupField {
  & label {
    & span {
      font-weight: var(--fontWeightSemiBold);
      font-size: 14px;
    }
  }
}
