@import '../../styles/customMediaQueries.css';
.predictionRoot {
  width: 100%;
  text-align: left;
  border: none;

  &.filterPredictionRoot {
    margin: 10px 0;
    @media (--viewportMedium) {
      margin: 0;
    }
  }
  & > ul {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + 4px);
    background-color: var(--colorWhite);
    border-radius: var(--borderRadiusMedium);
    box-shadow: var(--boxShadowPopup);
    z-index: calc(var(--zIndexPopup) + 1);
    margin-top: 0;
    max-height: 300px;
    overflow-y: auto;
    padding: 14px 0;

    & li {
      text-align: left;
      font-size: 15px;
      color: var(--colorGrey900);
      transition: var(--transitionStyleButton);
      margin: 0; /* Remove the double white space */
      line-height: 24px;
      /* keep horizontal space stable when hovering */

      /* Assign enough vertical padding to make the element at least 44px high */
      padding: 10px 24px;

      @media (--viewportMedium) {
        margin: 0;
      }

      &:hover,
      &.highlighted {
        cursor: pointer;
        color: var(--colorBlack);
        background-color: var(--colorGrey50);
      }
    }
  }
}
.customFilterSelect {
  & input {
    min-height: auto;
  }
}
.customSelect {
}

.preferredSchools {
  border: 1px solid #d8dce6;
  border-radius: 6px;
  &:hover {
    border: 1px solid #b8bfd1;
  }
}
