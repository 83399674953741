@import '../../styles/customMediaQueries.css';

.footer {
  background-color: var(--marketplaceColorDark);
  padding: 50px 24px;
  width: 100%;

  @media (--viewportMedium) {
    /* position: absolute; */
    left: 0;
    bottom: 0;
  }

  & .contentWidth {
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    @media (--viewportSmall) {
      flex-direction: row;
    }

    & .leftSect {
      max-width: 250px;
      margin-bottom: 20px;

      @media (--viewportSmall) {
        margin: 0;
      }

      &>a {
        padding: 0;
        height: auto;
        margin: 0 0 10px 0;

        &>div {
          height: auto;
          padding: 0;
          margin: 0;
        }
      }

      &>p {
        padding: 0;
        margin: 0 0 15px 0;
        color: var(--colorText);
        font-size: 18px;

        &:last-child {
          margin: 0;
        }
      }
    }

    & .rightSect {
      & .socialLinks {
        margin-top: 12px;

        &>a {
          &:not(:last-child) {
            margin-right: 20px;
          }

          &>svg {
            & path {
              fill: var(--colorText);
            }
          }

          &:hover {
            &>svg {
              & path {
                fill: var(--marketplaceColorLightBg);
              }
            }
          }
        }
      }

      & .quickLinks {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        &>a {
          color: var(--colorText);
          font-size: 18px;
          display: inline-block;
          text-decoration: none;

          &:not(:last-child) {
            margin: 0 0 10px 0;
          }

          &:hover {
            color: var(--marketplaceColorLightBg);
          }
        }

        & .copyright {
          color: var(--colorText);
          font-size: 18px;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}

.bottomBarSpacing {
  padding-bottom: 100px;

  @media (--viewportMedium) {
    padding-bottom: 50px;
  }
}

.seoPageWrapper {}

.countryListWrapper {
  padding: 0 24px 30px 24px;
  background-color: var(--colorText);

  & .contentWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
    padding: 40px 0px;

    &>h4 {
      color: var(--marketplaceColor);
      font-size: 35px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: left;
      align-self: flex-start;

      @media (max-width: 767px) {
        font-size: 26px;
      }

    }

    &>h2 {
      color: var(--colorTextDark);
      font-size: 35px;
      line-height: 100%;
      font-weight: var(--fontWeightBlack);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: left;
      align-self: flex-start;
    }

    &>p {
      color: var(--colorTextDark);
      font-size: 20px;
      line-height: 130%;
      font-weight: var(--fontWeightRegular);
      margin: 0 0 15px 0;
      padding: 0;
      text-align: justify;
      width: 76%;
    }

    & .countryList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      width: 100%;
      margin-top: 15px;

      @media (--viewportSmall) {
        grid-template-columns: repeat(6, 1fr);
      }

      & .countryName {
        color: var(--marketplaceColor);
        font-size: 15px;
        line-height: 130%;
        font-weight: var(--fontWeightRegular);
        margin: 0;
        padding: 0;
        cursor: pointer;
        transition: var(--transitionStyle);

        &:hover {
          cursor: pointer;
          transition: var(--transitionStyle);
          color: var(--marketplaceColorDark);
        }
      }
    }
  }
}

.UploadExcelFileMain {
  width: 30%;
}

.iconSpinner {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #f6fde8;

}