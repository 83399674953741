@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorText);
}

.iconSvg {
  margin: auto;
}
.headerSearch {
  flex-grow: 1;
  cursor: pointer;
  padding: 0 12px;
}
.locationForm {
  display: flex;
  align-items: center;
  width: 100%;
}
.mobileIcon {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
  & > svg {
    @media (--viewportMedium) {
      display: none;
    }
    transform: rotate(280deg);
    width: 14px;
    height: 14px;
    & > g {
      stroke: var(--colorTextDark);
    }
  }
}
.searchType {
  display: flex;
  align-items: center;
  & > span {
    font-size: 12px;
    line-height: 120%;
    color: var(--colorTextDark);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    width: max-content;
    position: relative;

    &:not(:last-child) {
      padding-right: 10px;
      margin-right: 10px;
      &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(0);
        right: 0px;
        background-color: var(--colorTextDark);
        width: 3px;
        height: 3px;
        border-radius: 100px;
      }
    }
  }
}
.clearSearch {
  cursor: pointer;
  @media (--viewportMedium) {
    margin-right: 10px;
  }
  & > svg {
    width: 24px;
    height: 24px;
  }
}
.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: 50px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;
  font-size: 18px;
  @media (--viewportMedium) {
    font-size: 20px;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  top: calc(100% + 13px);
  left: 0;
  /* background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup); */
  z-index: calc(var(--zIndexPopup) + 1);
}

.predictionsRootGoogle {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  padding-bottom: 72px;
}

.predictionsRootMapbox {
  /* Google Maps needs 72px bottom padding to accommodate logo, Mapbox doesn't have one */
  padding-bottom: 8px;
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left 36px;
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;

  margin: 0;
  padding: 14px 0;

  & li {
    color: var(--colorText);
    transition: var(--transitionStyleButton);
    margin: 0; /* Remove the double white space */
    line-height: 24px;
    /* keep horizontal space stable when hovering */
    /* border-left: 6px solid transparent; */
    text-align: left;
    font-size: 15px;
    font-weight: var(--fontWeightBold);

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 9px 0px;

    @media (--viewportMedium) {
      padding: 6px 0px;
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      width: fit-content;
      /* border-left: 6px solid var(--colorText);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px; */
      color: var(--marketplaceColorDark);
    }
  }
}

.currentLocation {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  font-size: 15px;
}

.currentLocationIcon {
  margin-right: 10px;
  margin-bottom: 1px;
  & > path {
    fill: var(--colorTextDark);
  }
}

.locationResults {
  display: flex;
  & > svg {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 8px;
    & path {
      fill: none;
      stroke: var(--colorTextDark);
    }
  }
  & .locationName {
    width: calc(100% - 16px);
  }
}

.selectedValues {
  display: flex;
  gap: 4px;
}

.hideDates {
  /* @media (max-width: 768px) { */
  display: none;
  /* } */
}
