@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
}

@media (--viewportMedium) {
  .root {
    margin-top: 48px;
  }
}

.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
  & > h3 {
    color: var(--colorText);
    font-size: 20px;
    font-weight: var(--fontWeightSemiBold);
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
  & > span {
    & > input:checked + label {
      & span {
        & svg {
          & circle {
            stroke: none;
          }
          & > g {
            stroke: var(--colorText);
            fill: var(--colorTextDark);
          }
        }
      }
    }
    & label {
      & span {
        & svg {
          & circle {
            stroke: var(--colorTextDark);
          }
        }
      }
    }
  }
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  color: var(--colorText);
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--colorTextDark);

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.missingStripeKey {
  color: var(--colorFail);
}

.accountInformationTitle {
  margin: 0;
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 16px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  composes: marketplaceInputStyles from global;
  color: var(--colorGrey700);
  text-align: left;

  &:hover {
    cursor: text;
    text-decoration: none;
  }
}

.modalTermsText {
  & > span {
    color: var(--colorGrey700);

    & > a {
      color: var(--marketplaceColorLight);
    }
  }
}

.submitButton {
  border: 1px solid;
}
