.icon {
  height: auto;
  width: 25px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.langSwitchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  & > div {
    & > button {
      padding: 0;
    }
  }
}

.labelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 45px;
  width: 45px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    &:hover {
      /* background-color: var(--marketplaceColorLight); */
    }
  }
}

.mobileLabel {
  @media (min-width: 768px) {
    display: none;
  }
}
