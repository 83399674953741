@import '../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  &:hover {
    border-color: var(--colorGrey200);
  }
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  display: flex;
  background-color: var(--colorText);
  padding: 0 14px;
}

.fontInput {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 5px 13px 15px 13px;
    margin: 0;
  }
}

.mobileInput {
  /* Font */
  composes: p from global;
  margin: 0;
  padding: 0;
  font-size: 18px;
  /* Layout */
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorText);
}

.desktopInput {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */
  /* min-height: calc(100vh - 144px); */
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorText);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.topbarSearchFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  & > * {
    position: relative;
    &:not(:last-child) {
      @media (--viewportMedium) {
        margin-right: 10px;
      }
    }
    &:hover {
      @media (--viewportMedium) {
        transform: scale(1.05);
      }
    }
    & > svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
  & .filterIcon {
    @media (max-width: 767px) {
      border: solid 1px var(--colorText);
      background-color: var(--colorText);
      border-radius: 100px;
      padding: 1px;
      width: 35px;
      height: 35px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      /* left: 6px; */
      & > svg {
        width: 18px;
        height: 18px;
      }
    }
    & .filterCount {
      position: absolute;
      top: -6px;
      right: -6px;
      background-color: var(--marketplaceColorDark);
      color: var(--colorText);
      font-size: 13px;
      border-radius: 100px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: solid 1px var(--colorText);
      width: 18px;
      height: 18px;
    }
    & > svg {
      fill: none;
    }
  }
  & .calendarIcon {
    display: none;
    @media (--viewportMedium) {
      display: block;
    }
    & > svg {
      width: 26px;
      height: 26px;
      cursor: pointer;
      & path {
        fill: #000;
      }
    }
  }
  & .vehicleIcon {
    display: none;
    @media (--viewportMedium) {
      display: block;
    }
    & > svg {
      cursor: pointer;
    }
  }
}

.searchIcon {
  border-radius: 100px;
  width: 30px;
  height: 30px;
  min-height: auto;
  padding: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--marketplaceColor);
  border: none;
  transition: var(--transitionStyle);
  color: var(--colorText);
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  display: none;
  @media (--viewportMedium) {
    display: inline-flex;
  }
  & > svg {
    width: 14px;
    height: 14px;
    transform: rotate(270deg);
    margin: 0 auto;

    & > g {
      stroke: var(--colorText);
    }
  }
  &:hover {
    background-color: var(--marketplaceColorDark);
    color: var(--colorText);
    border: none;
  }
}
