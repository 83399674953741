@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px; */
    column-count: 2;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 2px 0 6px 0;
  }
}
